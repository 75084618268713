import React, { useState, useEffect } from 'react';
import './style.scss';

function FadingElement({scroll, children}) {
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className={scrollY > scroll ? 'fade-in fade-in-active' : 'fade-in'}>
        {children}
    </div>
  );
};

export default FadingElement;