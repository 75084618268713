import * as React from "react"
import './style.scss';
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import VideoComponent from "../../components/videoComponent";
import FadingElement from "../../components/fadeElement";
import NavBar from "../../components/navbar";
// import ReCAPTCHA from "react-google-recaptcha";


// const validateEmail = (email) => typeof email === "string" && 
//                       email.includes("@") && 
//                       email.includes(".") && 
//                       email.length > 5 && 
//                       email.length < 50 &&
//                       email.indexOf("@") < email.lastIndexOf(".")
//                       && email.indexOf("@") > 0
//                       && email.lastIndexOf(".") < email.length - 1
//                       && email.lastIndexOf(".") > email.indexOf("@") + 1
//                       && email.indexOf("@") === email.lastIndexOf("@");

function HomePage(){
    //const [email, setEmail] = React.useState("");
    //const [name, setName] = React.useState("");
    //const [isDisabled, setIsDisabled] = React.useState(true);
    //const [captcha, setCaptcha] = React.useState(undefined);
    //const [formMessage, setFormMessage] = React.useState("");
    //const [mailing_popup, setMailingPopup] = React.useState(false);
    //const [success, setsuccess] = React.useState(false);
    // const onEmailChange = (event) => {
    //     setEmail(event.target.value);
    //   };

    //   const onNameChange = (event) => {
    //     setName(event.target.value);
    //   };

    // React.useEffect(() => {
    //     if (name.length > 0 && validateEmail(email) && captcha) {
    //         setIsDisabled(!validateEmail(email));
    //     } else {
    //         setIsDisabled(true);
    //     }
    //   }, [email, name, captcha]);

    const sellingPoints = [
        {text: "Create and command your own top-secret facility, where the impossible is reality.",
        image: "/images/screenshots/1.png"},
            ...shuffleArray([
                {text: "Unleash the power of AI storytelling and uncover mind-bending tales of love and loss.",
                image: "/images/screenshots/2.png"},
                {text: "Master the logistics of facility management, from electricity to waste disposal.",
                image: "/images/screenshots/3.png"},
                {text: "Expand your facility upwards into the skies, or deep into the depths of the earth.",
                image: "/images/screenshots/4.png"},
                {text: "Acquire and control a diverse group of prisoner test subjects and keep them in line amidst eldritch horrors.",
                image: "/images/screenshots/5.png"},
                {text: "Handle unexpected events and contain breaches with your elite OSTF team.",
                image: "/images/screenshots/6.png"},
                {text: "Conduct experiments with your collection of anomalies and unlock advanced gear for security and research.",
                image: "/images/screenshots/7.png"},
                {text: "Deploy field agents to manage public awareness and defend against anti-foundation attacks.",
                image: "/images/screenshots/8.png"},
                {text: "Call in the help of the Corporations's Elite Task Force to contain the worst of breaches.",
                image: "/images/screenshots/9.png"},
                {text: "Request Ordnance from the Corporations's arsenal when all is lost.",
                image: "/images/screenshots/10.png"},
                {text: "Immerse yourself in deep systems and lore, and strive to contain the uncontainable.",
                image: "/images/collage.webp"}
            ]),
        {text: "Multiple endings await, which one will you uncover?",
        image: "/images/world.webp"}
        ];

    // function onSubmission(event) {
    //     const url = "https://assets.mailerlite.com/jsonp/470003/forms/90414698183264124/subscribe";
    //     fetch(url, {
    //     method: "POST",
    //     headers: {
    //         "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ fields: {email: email, name: name }}),
    //     })
    //     .then((response) => {
    //         if (!response.ok) {
    //         setFormMessage("Error submitting the form. Please try again.");
    //         }else {
    //             setFormMessage(" Welcome to the Corp! Check your email!");
    //             setMailingPopup(true);
    //             setsuccess(true);
    //             console.log("success");
    //         }
    //         return response.json();
            
    //     })
    //     .catch((error) => {
    //         console.error("Error:", error);
    //         setFormMessage(error + ". This shouldn't happen. Please try again.");
    //     });

    //     event.preventDefault();
    //     }

    // function onCaptchaChange(value) {
    //     setCaptcha(value);
    // }

    return (
        <div className="main">
             {/* { mailing_popup &&
                <div className="mailing-list-popup">
                    <div className="img-holder">
                        <div className="exit" onClick={()=>setMailingPopup(false)}>X</div>
                        <img id="mailing_list_welcome_desktop" src="/images/mailing_list_welcome.webp" alt="mailing_list_welcome_desktop"/>
                        <img id="mailing_list_welcome_mobile" src="/images/mailing_list_welcome_mobile.webp" alt="mailing_list_welcome_mobile"/>
                    </div>
                </div>
            } */}
        <div className="main-inner">
        <NavBar/>
        <video autoPlay muted loop className="backgroundVideo" src="/video/static.mp4" />
        <VideoComponent/>
        <div className="filterGradient"></div>
        <div className="backgroundReflectedGradient"></div>
        <div className="backgroundGradientFadeOff"></div>
        <div className="black-bg"></div>
        <FadingElement scroll={800}>
            <div className="black"></div>
        </FadingElement>
        <div className="content homepage">
        
            <div className="box left">
                <div className="inner-box wiki-box" onClick={() => window.open("http://containcorp.wikidot.com/")}>
                <img src="/images/anon_wiki_logo.webp" alt="wiki_logo"/>
                    <button className="button" onClick={() => window.open("http://containcorp.wikidot.com/")}> Dive in</button>
                </div>
                <div className="inner-box about-box">
                    <img src="/images/plasmarc_logo.webp" alt="company_logo"/>
                    <p>Plasmarc Studios is a duo of geeky programmers, trying to create cool games.</p>

                    <p>We are dedicated to creating interesting games. As a recently founded studio, we are very fresh, which means we have the energy and motivation to bring great products. <b>We may be a small team, but we by no means make small games.</b></p>

                    <p>Originally founded to complement our main game Containcorp on the 26th August 2020. Let's see where it takes us.</p>
                </div>
            </div>
            <div className="box right">
                <div className="inner-box steam-box">
                    <img src="/images/steam_logo.webp" alt="steam_logo"/>
                    <button className="button" onClick={() => window.open("https://store.steampowered.com/app/2483140/Containcorp/")}> Wishlist Now</button>
                </div>
                <div className="inner-box discord-box">
                    <img src="/images/discord_logo.webp" alt="discord_logo"/>
                    <button className="button" onClick={() => window.open("https://discord.gg/hjpFzzS")}> Join the Corp!</button>
                </div>
                <div className="inner-box patreon-box">
                    <img src="/images/patreon_logo.webp" alt="patreon_logo"/>
                    <button className="button" onClick={() => window.open("https://www.patreon.com/Plasmarcstudios")}> Support us</button>
                </div>
                <div className="inner-box youtube-box">
                    <img src="/images/youtube_logo.webp"  alt="youtube_logo"/>
                    <button className="button" onClick={() => window.open("https://www.youtube.com/channel/UC92eNdWwMQ-wrdOPOYgTfUQ")}> Support us</button>
                </div>
            </div>
            <div className="box-small">
                <img src="/images/logosmall/steam_logo_small.webp" alt="steam_logo" onClick={() => window.open("https://store.steampowered.com/app/2483140/Containcorp/")}/>
                <img src="/images/logosmall/discord_logo_small.webp" alt="discord_logo" onClick={() => window.open("https://discord.gg/hjpFzzS")}/>
                <img src="/images/logosmall/patreon_logo_small.webp" alt="patreon_logo" onClick={() => window.open("https://www.patreon.com/Plasmarcstudios")}/>
                <img src="/images/logosmall/youtube_logo_small.webp" alt="youtube_logo" onClick={() => window.open("https://www.youtube.com/channel/UC92eNdWwMQ-wrdOPOYgTfUQ")}/>
            </div>
            <div className="contentWrapper">
                <div className="contentMain">
                    <CarouselProvider
                        className="img-carousel"
                        naturalSlideWidth={100}
                        naturalSlideHeight={70}
                        totalSlides={sellingPoints.length}
                        isPlaying={true}
                        interval={5000}
                    >
                        <Slider className="slider">
                            {
                                sellingPoints.map((point, index) => {
                                    return (
                                        <Slide index={index} key={index}>
                                            <img src={point.image} alt="carousel_slide"/>
                                            <h1>{point.text}</h1>
                                        </Slide>
                                    )
                                })
                            }
                        </Slider>
                        <DotGroup className="dot-group"/>
                    </CarouselProvider>
                    <div className="wishlist-widget">
                    <iframe title="Wishlist" src="https://store.steampowered.com/widget/2483140/" frameborder="0" width="646" height="190"></iframe>
                    </div>
                    <h1 className="info-text">
                        Containcorp is...
                    </h1>
                    <h1 className="info-text">A Storydriven Management Simulation Game inspired by the SCP Universe.</h1>
                    <h1 className="info-text">
                        Containcorp has you managing a top-secret worldwide government-run facility 
                        that specialises in containing and experimenting on anomalous entities. Set in its own universe 
                        but <b>inspired by the SCP universe,</b> you will encounter a multitude of anomalous objects and entities. 
                        What you learn about each and how is up to you...
                    </h1>
                    {/* <div className="section">
                        <h1 className="title">
                            Mailing List 
                        </h1>
                         <hr/>
                        <div id="lenny1"> (✿ ♡‿♡)</div>
                        <p> 
                            Containcorp is a game currently a game in development by a small team of developers. This website shows
                            what the vision is going to be. By the time the steam page is live, the game will be in a playable early access state.
                            The early access will contain many of the features that you will see below, but potentially not all of them.
                            By wishlisting, you will be able to get the game at a discounted price and get access to the early access...earlier 
                            than the rest. You will also help us achieve the vision that we have for the game!
                            Marketing isn't cheap, and we want to make sure that the game gets the attention it deserves.
                            Below you will find what our vision is for the game and what we hope to achieve, and a link to the mailing list to keep up to date with the game and be notified when it lauched on steam!
                        </p>
                        <div>
                            <form onSubmit={onSubmission} data-code="" method="post" target="_blank">
                                {!success &&
                                <div className="inputs">
                                    <input aria-label="name" type="text" data-inputmask="" name="fields[name]"  autoComplete="given-name"  placeholder="What should we call you?" onChange={onNameChange} value={name}></input> 
                                    <input  aria-label="email" aria-required="true" type="email" name="fields[email]" placeholder="Your Email" autoComplete="email" onChange={onEmailChange} value={email}></input> 
                                    <input disabled={isDisabled || !captcha} type={'submit'} value={'Sign me up!'} className={isDisabled || !captcha ? 'hidden-submit-button' : 'submit-button'} onClick={isDisabled || !captcha ? invalidEmail : buttonClick}></input>
                                    
                                </div>
                                }
                                { !formMessage &&
                                    <p> 
                                        We promise not to spam you, and we will only send you emails relevant about the game!
                                        Keeping a mailing list is surprisingly expensive, so we will only send you emails when we have something cool to tell you.
                                    </p>
                                }
                                { formMessage &&
                                    <p> 
                                        {formMessage}
                                    </p>
                                }
                               {!success &&
                                <ReCAPTCHA
                                    sitekey="6Lf1KHQUAAAAAFNKEX1hdSWCS3mRMv4FlFaNslaD"
                                    onChange={onCaptchaChange}
                                />
                               }
                            </form>
                        </div>
                        
                    </div> */}
                   
                            {/* Containcorp is a game currently a game in development by a small team of developers. This website shows
                            what the vision is going to be. By the time the kickstarter is live, the game will be in a playable early access state.
                            The early access will contain many of the features that you will see below, but potentially not all of them.
                            By backing the kickstarter, you will be able to get the game at a discounted price and get access to the early access...earlier 
                            than the rest. You will also help us achieve the vision that we have for the game!
                            The kickstarter will be used to fund the development of the game, keep us alive and fed, and help us get the game out to the public.
                            Marketing isn't cheap, and we want to make sure that the game gets the attention it deserves.
                            Below you will find what our vision is for the game and what we hope to achieve.
                            And a link to the mailing list to keep up to date with the game and the kickstarter. */}
                    <div className="section">
                        <h1 className="title">
                            The Game
                        </h1>
                        <hr/>
                        <p>
                        Welcome to the world of anomalous containment and research! As the director of a top-secret facility, you will be tasked with building and managing a base to contain and study the most bizarre and mysterious entities and objects on the planet.
                        Start by selecting the perfect location for your facility, and use your grant to construct containment areas, staff quarters, and research labs. As you progress, you'll receive requests to contain increasingly challenging anomalies, and must make strategic decisions to invest in specialized teams and equipment to successfully capture and contain them.
                        But it's not just about the containment, you'll also need to manage your staff and keep them sane while dealing with eldritch horrors. And be prepared to face off against anti-foundation groups, who will stop at nothing to raid your facility and steal your anomalies.
                        With a unique blend of base-building, management and anomalous containment, this game will challenge your strategic and tactical skills. Can you keep the anomalies contained and your staff sane?
                        </p>
                        {/* <h1>
                            In Containcorp, you are not limited by the laws of physics, you are limited by your imagination.
                        </h1> */}
                    </div>
                    <div className="section">
                        <h1 className="title">
                            Containing the Uncontainable: The Anomalies
                        </h1>
                        <div className="imgMaskContainer">
                            <img className="backgroundImage" src="/images/collage.webp" alt="document_collage"/>
                        </div>
                        <p className="text-center" style={{marginTop: 150}}> 
                        <b>In Containcorp,you will be tasked with containing and researching a wide variety of anomalous entities and objects. </b> 
                         All these anomalies are pre-written and hand-crafted by our team of writers (us), and will be added to the game as we progress 
                        through development. Each anomaly will have its own unique story in the context of the lore, and will be a challenge to contain.
                        Anomalies will come in all shapes and sizes, from small objects to large creatures, and will have a variety of abilities and powers, and
                        with that comes a variety of containment methods. Some anomalies will be easy to contain, while others will be a nigh impossible task.
                        Anomalies are the heart of the game, and we are excited to share them with you!
                        </p>
                    </div>
                    
                    <div className="section" style={{marginTop: 250}}>
                        <h1 className="title">
                            Structural Integrity & Multiple Floors
                        </h1>
                        <hr/>
                        <p> 
                            <b>In Containcorp, you are not limited to a single floor. </b> You can build multiple floors and connect them with elevators and stairs.
                            Whether you choose to build above ground into the skies above, or below ground into the depths of the earth, is up to you.
                            With this comes many challenges and trade-offs. If you are building high, you will need to build a strong foundation to support 
                            the weight of the floors above, however you will have a much larger area to work with, and be able to build with ease.
                             If you are building below ground, you will have to contend with the weight of the floors above, and the pressure of the earth above you, a much harder
                             challenge. However, building underground has its merits too. You will be able to build in a much more secure facility, and contain the 
                             horrors of the Corporation from the outside world much easier.
                        </p>
                        <div className="imgHolder">
                            <img src="/images/multipleFloorsGraphic.webp" alt="multiple_floors_illustration"/>
                        </div>
                    </div>
                    
                    <div className="section">
                        <h1 className="title">
                            The Dynamic Storyteller
                        </h1>
                        <hr/>
                        <p> 
                            The Dynamic Storyteller is a unique feature of Containcorp.
                            <b> The Dynamic Storyteller is neither a friend nor an enemy, but rather a neutral party that will present you with challenges and opportunities. </b> 
                            The Dynamic storyteller is designed to create engaging, coherent stories within a game. 
                            By creating events that create stories with hooks, falls, rising actions, 
                            climaxes, and denouements on a daily basis, each day will be much different from the last. 
                            The storyteller is designed not only to provide interesting daily scenarios but resolve to broader story 
                            arcs that span months or even years in-game.
                            
                            
                        </p>
                        <p>
                            <em>
                                The story of the rise of a new facility, the influx of researchers and investment opportunities, 
                                an age of innovation and technology, a "fly too close to the sun" moment and the inevitable total collapse 
                                of the facility into disrepair.
                            </em>
                        </p>
                        <p>
                            <em>
                                Or perhaps, the story of a facility left in ruin, brought back to its former glory, only to fall again into ruin, 
                                but this time take the entire world with it.
                            </em>
                        </p>
                        <p>
                            These stories would all possibilities within the game's mechanics but without a storyteller 
                            to guide the helm, they are diluted amongst the sea of randomness.
                            By using the storyteller we can nudge the gameplay into making more interesting playthroughs over the many hours of 
                            playtime.
                         </p>
                         <h1 className="tagline">
                            Real life is often boring, that's why authors exist. A book is not a simulation of a world, it is an illusion of one.
                            Containcorp aims to a novel. One which you can play and experience.
                        </h1>
                    </div>
                    
                    <div className="section">
                        <h1 className="title">
                            Health Systems & Modelling
                        </h1>
                        <hr />
                        <p> 
                            <b>In Containcorp, health is not just a number. Health is a system. </b> Each bodypart is modelled 
                            and tracked on the bodies of NPCs. If a NPC is shot in the arm, they will bleed out, if they
                            are shot in the leg, they will limp, if they are shot in the head, they will die.
                            All wounds have long term consequences on an NPCs life, but you can find the means 
                            to heal most wounds if you are resourceful enough. In a world of anomalies, there is much to 
                            discover and use to your advantage. 
                        </p>
                        <p> 
                            The limitations of flesh and bone can be overcome with the right technology. Organs can be regrown,
                            limbs can be replaced, bionics can be surgically implanted, whole bodies can be reshaped to your will.
                            If you want a staff member to be able to run faster, you can give them a bionic leg. If you want a staff member
                            to be able to see in the dark, you can give them a bionic eye. If you want a staff member to be able to lift
                            heavy objects, you can give them a bionic arm. If you want a staff member to be able to survive a gunshot to the head,
                            you can give them a bionic brain. Or perhaps you want to convert all your staff members into synthetic superhumans, or command a legion of 
                            cyborg security guard. The possibilities are endless.
                        </p>
                        <h1 className="tagline">
                            Fill an room with noxious gas, and watch as your staff members suffocate. Fill a room with water, 
                            and watch as your staff members drown. Fill a room with fire, and watch as your staff members burn; 
                            and then watch as they are reborn as sythetic superhumans.

                        </h1>
                        <div className="imgHolder" style={{marginTop: 40}}>
                            <img src="/images/npc_health.webp" alt="npc_health_screenshot"/>
                        </div>
                    </div>
                    <div className="section">
                        <h1 className="title">
                            Ballistic Model & Gunplay
                        </h1>
                        <hr/>
                        <p> 
                            <b>Weapons are modelled to be less about numerical math and more about real world physics. </b>
                            The ballistics model is designed to be as realistic as possible, with the goal of making
                            gunplay feel like a real life gunfight, and not a game of numbers. Bullets follow a trajectory, 
                            and contact NPCs, tearing through their bodies, and hitting the wall behind them. 
                        </p>

                        <p> 
                            A predictable gunfight
                            is a boring gunfight. But an completely random gunfight is a frustrating gunfight. In Containcorp,
                            we aim to strike a balance between the two. <b>No gunfight will ever be the same, but you will
                            always know what to expect. </b> A careful plan and a well placed shot will always be rewarded. 
                            A terrible plan and a poorly placed shot will always be punished. Though the game is not
                            designed to be a shooter, it is designed to be a game where you can use guns to your advantage, and with 
                            the right strategy, and a pinch of luck, weapons will become a powerful tool in your arsenal.
                        </p>
                        <h1 className="tagline">
                            Whether it's calling an airstrike on a group of raiders, or using a sniper rifle to pick off a
                            single escaped Category C; using a shotgun to blast through a door to escape a doomed facility,
                            or calling a nuclear strike to stop a world ending event; weapons are a vital part of Containcorp.
                            
                        </h1>
                    </div>
                    <div className="section">
                        <h1 className="title">
                            Logistical Planning: Pipes & Cables
                        </h1>
                        <hr/>
                        <p> 
                        <b>Containcorp is as much a game about logistics as it is about anomalies. </b> The game is designed to be a simulation of a facility, and as such, it is designed to simulate the logistical challenges of running a facility. Pipes and cables are a vital part of any facility, and Containcorp is no different. There are many consequences to not planning your infrastructure properly. Gases can accumulate in rooms, and cause suffocation. Water can accumulate in rooms, and cause drowning. Power can be lost, and cause lights to go out. All of these may make the difference between a successful containment, and a failed one.
                        </p>
                    </div>
                    <div className="section">
                        <h1 className="title">
                            Category C: Your Test Subjects
                        </h1>
                        <hr/>
                        <p> 
                        <b>Category C are the test subjects of Containcorp. </b> It turns out that the best way to test the limits of your containment is to put a human in it. Category C are your go to guinea pigs, when experimenting on and assessing anomalies under your confinement. They are not expendable however, and as such, you must take care of them. They are not just a number, they are a person. They have a name, a family, a life. They have hopes, dreams, and fears. They have a personality, and they have a story.
                        </p>

                        <p>
                        Most of the Category C you intake will be volunteers, but some may be unwilling criminals, or even unwilling victims. Some may be willing to help you, and some may be willing to help themselves.
                        </p>
                    </div>
                    <div className="section">
                        <h1 className="title">
                            Public Awareness & Containment Breaches
                        </h1>
                        <hr/>
                        <p> 
                        In Containcorp, <b>the wider world is aware of the existence of your facility, but the details of your operations are kept secret. </b> However, containment breaches can occur and it is up to you to contain them and limit the exposure of the anomalies to the public. A containment breach can have serious consequences, both for your reputation and the safety of the public, so it is important to invest in a secure containment system and prepare for the worst. Your actions, both successes and failures, will affect the public's perception of your facility and influence their willingness to fund your future endeavors. Additionally, you may encounter anti-foundation groups who seek to raid your facility and steal the anomalies, putting both your staff and the public at risk. It is up to you to decide how to deal with these threats and maintain the secrecy and safety of your operations, all while advancing your research and expanding your facility.   
                        </p>
                    </div>
                    <div className="section">
                        <h1 className="title">
                            The Universe
                        </h1>
                        <hr/>
                        <p> 
                        The Universe of Containcorp is a dark and dangerous place. It is a place where the laws of physics are bent, and the laws of nature are broken. Anomalies appeared on earth in the 1990s, and since then, the world has been changed forever. The world is a perilous place, and it is up to you to keep it safe. The Corporation came into existence as it is known now at the turn of the century. It is now the only thing standing between the world and the horrors of the anomalies, horrors which appear at random, and without warning. Many years have passed since the first anomalies appeared, and the world is still unaware of the existence of the anomalies. The Corporation exists to keep it that way, and... to profit from it.
                        </p> 
                        <p>
                        Other institutions have sprung up in the wake of the anomalies, some of them are friendly, and some of them are not. The Corporation must work with or fight against these other institutions to keep the world safe, and to keep the anomalies contained.
                        </p>
                        
                        <h1 className="tagline">
                        The stories of the future all speak of a time when the world was changed forever. A time when the world was changed by the anomalies. A time when the world was changed by you.
                        </h1>
                    </div>
                </div>
            </div>

        </div>
        </div>
      </div>
    )
  }




// function buttonClick() {
//     //alert("You've been added to the mailing list!");
// }
  
// // wouldn't run unless a sussy baka edited the html
// // and pressed the button
// function invalidEmail() {
//     alert("Please enter a valid email address!");
// }

/* Randomize array in-place using Durstenfeld shuffle algorithm */
function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}

export default HomePage;
