import * as React from "react"
import './style.scss';
import { useNavigate } from "react-router-dom";

function NavBar({color}){
  const navigate = useNavigate()

    return (
      <div className="navbar-component" style={{backgroundColor: color}}>
        <div className="logo">
          <img src="/images/containcorpLogoALT.webp" alt="logo"/>
        </div>
        <div className="navButtons">
        <div className="navButton" onClick={() => navigate(`/`)}>
            Home
        </div>
        <div className="navButton" onClick={() => window.open("https://plasmarcstudios.co.uk/containcorp-blog")}>
            Dev Blog
        </div>
        <div className="navButton" onClick={() => navigate(`/faq`)}>
            FAQ
        </div>
        <div className="navButton" onClick={() => window.open("https://plasmarcstudios.co.uk/containcorp-presskit")}>
            Presskit
        </div>
        <div className="navButton" onClick={() => window.open("mailto: plasmarcstudios@gmail.com")}>
            Contact
        </div>
        </div>
      </div>
    )
  }

export {NavBar};
