import * as React from "react";
import LandingPage from './main';
import './root.scss';
import { HashRouter } from 'react-router-dom'
const IndexPage = () => {
  return (
    <div className="root">
      <HashRouter>
        <LandingPage/>
      </HashRouter>
    </div>
  );
}

export default IndexPage;

export const Head = () => <title>Home Page</title>
