import * as React from "react"
import 'pure-react-carousel/dist/react-carousel.es.css';


function VideoComponent(){
    var [count, setCount] = React.useState(0);
    React.useEffect(() => {
        const interval = setInterval(() => {
            var rand = randomInt(4);
            var newCount = count
            if (rand === count) {
                newCount+=1;
            }else{
                newCount = rand;
            }
            if (newCount + 1 > 4) {
                setCount(0);
            }else{
                setCount(newCount);
            }
        }, (randomInt(30)+4) * 1000);
        return () => clearInterval(interval); 
    }, [count]);
    const src = `/video/Alex Renders/${count}.mp4`;
    return (
        <video autoPlay muted loop className="backgroundVideo" src={src} />
    )
}

function randomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  export default VideoComponent;