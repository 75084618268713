import * as React from "react"
import './main.scss';
import {Routes, Route} from "react-router-dom";
import 'pure-react-carousel/dist/react-carousel.es.css';
import HomePage from "./HomePage/homePage";
import FAQ from "./FAQ/faq";

function LandingPage(){
    return (
        <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/faq" element={<FAQ/>} />
        </Routes>
      );
  }


export default LandingPage;
