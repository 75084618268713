import * as React from "react"
import './style.scss';
import VideoComponent from "../../components/videoComponent";
import NavBar from "../../components/navbar";


function FAQ(){
    var faq = [
        {question: "Is this game set in the SCP Universe?", 
        answer: "<b>This game is not set in the SCP Universe</b>, we have crafted our own unique universe that takes heavy inspiration from the SCP universe. We decided this so we can have full creative freedom over the game and not be restricted by the SCP universe, and additionally provide a fresh take on certain aspects. We also want to avoid any legal issues that may arise from using the SCP universe."},
        {question: "Is the gameplay more similar to Prison Architect or a Rimworld?", answer: "<b>A bit of both actually.</b> The game takes aspects from both games. In short, the game has the core gameplay of Prison Architect with the soft gameplay of Rimworld. The game is aiming to be a deep simulation game, so naturally it will be similar to both games. We are trying to make the game as unique as possible though, so it doesn't feel like a clone of either game."},
        {question: "Why does the artstyle look so similar to Rimworld?", answer: "The artstyle is more of a mix of both Prison Architect and Rimworld respectively. <b>This artstyle was chosen as the game is a simulation game to the core<b>, which complements a more simplistic artstyle.</b> The artstyle allows us to focus on the deep simulation instead of the way a character's leg is animated for example. The artstyle can and probably will go through revisions in the future though."},
        {question: "Are there going to be multiple floors?", answer: "<b>Yes.</b> A multiple floor system has already been implemented. We hope this helps differentiate the game from its predecessors."},
        {question: "Is there a beta on any platform or are we still closed development?", answer: "The game is <b>not in a 'playable' state currently</b> but we are getting there day by day. We plan to release it in early access once we have the core game and features in place and it reaches a playable state."},
        {question: "Where is the roadmap?", answer: "You can find our roadmap on our <a href='https://plasmarc-studios.github.io/' target='_blank'>development blog.</a>"},
        {question: "Is there a chance for a open alpha soon?", answer: "For the immediate future, unfortunately no, as there isn't anything to 'play' as such. <b>We are planning to run closed aplha testing at the start of 2024,</b> which should be around the time the gme is playable."},
        {question: "Any patreon/kickstarter yet?", answer: "We are not running a kickstarter, we have a <a href='https://www.patreon.com/Plasmarcstudios' target='_blank'>patreon</a> but <b>we are largely self funded.</b> We really don't want much patreon help. We aren't fond of paywalls!"},
        {question: "Are we going to add modding support to the game?", answer: "<b>We plan to support modding in the future</b>, but it is not a priority at the moment. We want to get the core game done first."},
        {question: "When is it gonna be released?", answer: "It's likely going to be released around the <b>mid 2024 to the end of 2024 in early access.</b> It's hard to tell since all the developers (2 full time devs and 1 part time) are currently studying at university."},
        {question: "How much will it cost when it releases?", answer: "Around <b>10 GBP</b> ( 11 EUR, 14 USD) during early access, and up to <b>25 GBP</b> (29 EUR, 31 USD) when it fully releases."},
        {question: "Will we add our own SCPs or just use existing ones?", answer: "We chose not to use the existing SCP universe for our game, instead <b>we use our own ideas</b> and user submitted ideas for the SCP-like entities in the game. <a href='https://forms.gle/adpvPnWEXDr9vjno7' target='_blank'>We have a google form for user submissions!</a>"},
        {question: "How can I submit an anomaly to become apart of the game?", answer: "Use our short and simple <a href='https://forms.gle/adpvPnWEXDr9vjno7' target='_blank'>google form sheet.</a>"},
        {question: "So what's the tech level in the world of containcorp?", answer: "The universe is set in a modern to near future technology world."},
        {question: "What kind of music are you planning to put into the game?", answer: "We have a soundtrack already, but currently placeholders. Though we are planning to remove them all to make sure we get complete silence for maximum atmosphere. Jokes aside, we will likely hire a composer to compose custom music."},
        {question: "What technology does Containcorp use?", answer: "Containcorp uses the Unity Framework."},
        {question: "Can I intern/work at Plasmarc Studios?", answer: "If this game does well, then we will definitely consider hiring people. We are currently not hiring anyone, but we will be in the future. Regardless if you are interested in working with us, then please send us an email at plasmarcstudios@gmail.com with your portfolio and we will get back to you if we are interested."},
        {question: "What rank are we in CSGO?", answer: "We are both in Gold Nova!"},
    ]
    return (
        <div className="main">
        <div className="main-inner" style={{height: "100%"}}>
        <NavBar color="#1e1f26"/>
        <video autoPlay muted loop className="backgroundVideo" src="/video/static.mp4" />
        <VideoComponent/>
        <div className="filterGradient"></div>
        <div className="backgroundReflectedGradient"></div>
            <div className="faq">
                <section className="container">
                    <header className="faq_header">
                        <h2 className="faq_header-title">Frequently Asked Questions</h2>
                    </header>
                    <div className="faq__body">
                        {
                            faq.map((item, index) => {
                                return (
                                    <details key={index} className="faq__panel">
                                        <summary className="faq__label">{item.question}</summary>
                                        <div className="faq__panel-body">
                                        <p className="faq__panel-answer" dangerouslySetInnerHTML={{__html: item.answer}}></p>
                                        </div>
                                    </details>
                                )
                            })
                        }
                    </div>

                </section>
            </div>
        </div>
      </div>
    )
  }

export default FAQ;
